var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zt-page-content"},[_c('div',{staticClass:"zt-block"},[_c('el-row',{staticClass:"demand-query",attrs:{"type":"flex","align":"middle"}},[_c('el-form',{staticStyle:{"height":"40px"},attrs:{"model":_vm.queryOpt,"inline":""}},[_c('el-form-item',{attrs:{"label":"交货日期"}},[_c('el-date-picker',{staticStyle:{"width":"380px"},attrs:{"type":"daterange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期"},on:{"change":_vm.getDate},model:{value:(_vm.queryOpt.daterange),callback:function ($$v) {_vm.$set(_vm.queryOpt, "daterange", $$v)},expression:"queryOpt.daterange"}})],1),_c('el-form-item',{attrs:{"label":"订单状态"}},[_c('el-select',{attrs:{"clearable":"","placeholder":"全部"},model:{value:(_vm.queryOpt.orderState),callback:function ($$v) {_vm.$set(_vm.queryOpt, "orderState", $$v)},expression:"queryOpt.orderState"}},_vm._l((_vm.orderStateOption),function(item){return _c('el-option',{key:item.optionCode,attrs:{"label":item.optionValue,"value":item.optionCode}})}),1)],1)],1)],1)],1),_c('div',{staticClass:"zt-block",staticStyle:{"height":"calc(100% - 200px)","overflow":"hidden","margin-bottom":"10px"}},[_c('div',{staticClass:"list",staticStyle:{"height":"100%","overflow":"auto"}},[_vm._l((_vm.list),function(item,idx){return _c('div',{key:idx,staticClass:"list-item1",staticStyle:{"min-width":"1330px"}},[(item.id)?_c('el-row',{staticClass:"item-info"},[_c('el-col',{staticClass:"order-header"},[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":4}},[_c('img',{staticClass:"image",attrs:{"src":_vm.imgUrl +
                    item.requirement.gaikuang.productImgs[0] +
                    '!width_250px',"width":"180","alt":"加载中..."},on:{"click":function($event){$event.stopPropagation();return _vm.goto(
                      '/want/demandCustomize/customizeDetail/' +
                        item.id +
                        '?type=2'
                    )}}})]),_c('el-col',{staticClass:"content",attrs:{"span":20}},[_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',[_c('h3',[_vm._v(_vm._s(item.requirement.title))]),_c('div',{staticClass:"detail"},[_c('span',[_vm._v("交易日期:")]),_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.createdAt,"YYYY-MM-DD HH:mm:ss")))])]),_c('div',{staticClass:"detail"},[_c('span',[_vm._v("发起时间:")]),_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.tradeOrder.createdAt,"YYYY-MM-DD")))])]),_c('div',{staticClass:"detail"},[_c('span',[_vm._v("实际出价:")]),_c('span',{staticClass:"highlight"},[_vm._v("¥"+_vm._s(_vm._f("priceFilt")(item.tradeOrder.ar)))]),_c('span',[_vm._v("  已收款:")]),_c('span',[_vm._v("¥"+_vm._s(_vm._f("priceFilt")(item.tradeOrder.paidAmount)))])])]),_c('el-col',{staticStyle:{"text-align":"right"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.goto(
                          '/want/wantFollow/want/detail/' + item.requirementId
                        )}}},[_vm._v("查看原需求")]),_c('el-button',{on:{"click":function($event){return _vm.goto(
                          '/want/demandCustomize/customizeDetail/' +
                            item.id +
                            '?type=2'
                        )}}},[_vm._v("订单详情")])],1)],1),_c('el-row',{staticClass:"company",attrs:{"type":"flex","align":"middle"}},[_c('div',{staticStyle:{"width":"33px","height":"33px","border-radius":"50%","overflow":"hidden","margin-right":"8px"}},[(item.buyerComp.logo)?_c('div',{staticClass:"bg_img",style:({
                        backgroundImage:
                          'url(' + _vm.imgUrl + item.buyerComp.logo + ')',
                      })}):_c('div',{staticClass:"bg_img",style:({
                        backgroundImage:
                          'url(' +
                          _vm.staticUrl +
                          'design/placeholder/zhanweilogo%402x.jpg!width_250px' +
                          ')',
                      })})]),_c('span',[_vm._v(_vm._s(item.buyerComp.name))])])],1)],1)],1),_c('el-col',{staticClass:"order-footer"},[_c('SignPlan',{attrs:{"item":item,"planType":'gk_order'},on:{"refresh":_vm.refreshItems}})],1)],1):_vm._e()],1)}),(_vm.list && _vm.list.length == 0)?_c('div',{staticClass:"flex flex-center",staticStyle:{"text-align":"center","height":"100%"}},[_c('div',[_c('img',{staticStyle:{"width":"110px"},attrs:{"src":_vm.staticUrl + '/design/placeholder/meiyougengduo.png',"alt":""}}),_c('div',{staticClass:"no-data"},[_vm._v("暂无相关数据")])])]):_vm._e()],2)]),_c('div',{staticStyle:{"padding":"7px"}},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next,total","current-page":_vm.queryOpt.page.pageNumber,"page-size":_vm.queryOpt.page.pageCount,"total":_vm.total},on:{"update:currentPage":function($event){return _vm.$set(_vm.queryOpt.page, "pageNumber", $event)},"update:current-page":function($event){return _vm.$set(_vm.queryOpt.page, "pageNumber", $event)}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }